import React from "react";
import { Link } from "gatsby";

import logo from "../../images/aisme_labs_logo.svg";

const Footer = () => {
  return (
    <>
      <div className="footer__main"></div>
      <footer className="footer ">
        <div className="container">
          <div className="footer__contents ">
            <div className="row">
              <div className="col-xl-6  col-md-3 col-sm-12">
                <figure className="footer__logo">
                  <img src={logo} alt="aisme footer logo" />
                </figure>
              </div>
              <div className="col-xl-2 col-md-3 col-sm-12">
                <div className="footer__widget">
                  <ul className="footer__widget-list">
                    <li className="footer__widget-items footer__widget-items-title">
                      <Link to="#">For SMEs</Link>
                    </li>
                    <li className="footer__widget-items">
                      <Link to='/join-as-sme'>Join as an SME</Link>
                    </li>
                    <li className="footer__widget-items">
                      <Link to="#">Join as an SMEOps</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-md-3 col-sm-12">
                <div className="footer__widget">
                  <ul className="footer__widget-list">
                    <li className="footer__widget-items footer__widget-items-title">
                      <Link to="#">For Clients</Link>
                    </li>
                    <li className="footer__widget-items">
                      <Link to="/find-as-sme">Find an SME</Link>
                    </li>
                    <li className="footer__widget-items">
                      <Link to="#">Request more information</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-1 col-md-3 col-sm-12">
                <div className="footer__widget">
                  <ul className="footer__widget-list">
                    <li className="footer__widget-items footer__widget-items-title">
                      <Link to="#">Resources</Link>
                    </li>
                    <li className="footer__widget-items">
                      <Link to="#">About</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12 m-auto">
                <div className="footer__copyright">
                  <Link to="#" className="footer__copyright-text">
                    Privacy Policy
                  </Link>
                  <Link to="#" className="footer__copyright-text">
                    Terms of Service
                  </Link>
                  <span className="footer__copyright-text">
                    Copyright @ AISMELabs.com
                  </span>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="footer__btnWrapper">
                  <Link to="#banner" className="footer__button">
                    Back to top
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
