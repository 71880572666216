import React from "react";
import { Link } from "gatsby";

import ContentImage from "../../images/content-image.png";

const Content = () => {
  return (
    <div className="content">
      <div className="container">
        <div className="content__item">
          <div className="content__wrapper">
            <h2 className="content__title mb-3">
              Step one sample title goes here
            </h2>
            <p className="content__desc mb-3">
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from 45 BC,
              making it over 2000 years old.Contrary to popular belief, Lorem
              Ipsum is not simply random text. It has roots in a piece of
              classical Latin literature from 45 BC, making it over 2000 years
              old.
            </p>
            <button type="button" className="btn btn-secondary mb-4 mb-sm-0">
              <span>Learn More</span>
            </button>
          </div>
          <Link className="">
            <img src={ContentImage} alt="" />
          </Link>
        </div>
        <div className="content__item">
          <div className="content__wrapper">
            <h2 className="content__title mb-3">
              Step one sample title goes here
            </h2>
            <p className="content__desc mb-3">
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from 45 BC,
              making it over 2000 years old.Contrary to popular belief, Lorem
              Ipsum is not simply random text. It has roots in a piece of
              classical Latin literature from 45 BC, making it over 2000 years
              old.
            </p>
            <button type="button" className="btn btn-secondary mb-4 mb-sm-0">
              <span>Learn More</span>
            </button>
          </div>
          <Link className="">
            <img src={ContentImage} alt="" />
          </Link>
        </div>
        <div className="content__item">
          <div className="content__wrapper">
            <h2 className="content__title mb-3">
              Step one sample title goes here
            </h2>
            <p className="content__desc mb-3">
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from 45 BC,
              making it over 2000 years old.Contrary to popular belief, Lorem
              Ipsum is not simply random text. It has roots in a piece of
              classical Latin literature from 45 BC, making it over 2000 years
              old.
            </p>
            <button type="button" className="btn btn-secondary mb-4 mb-sm-0">
              <span>Learn More</span>
            </button>
          </div>
          <Link className="">
            <img src={ContentImage} alt="" />
          </Link>
        </div>
        <div className="content__item">
          <div className="content__wrapper">
            <h2 className="content__title mb-3">
              Step one sample title goes here
            </h2>
            <p className="content__desc mb-3">
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from 45 BC,
              making it over 2000 years old.Contrary to popular belief, Lorem
              Ipsum is not simply random text. It has roots in a piece of
              classical Latin literature from 45 BC, making it over 2000 years
              old.
            </p>
            <button type="button" className="btn btn-secondary mb-4 mb-sm-0">
              <span>Learn More</span>
            </button>
          </div>
          <Link className="content__image " to="/">
            <img src={ContentImage} alt="" />
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Content;
