import { Link } from "gatsby";
import React, { useEffect, useState } from "react";

import logo from "../../images/logo.svg";
import logoBlk from "../../images/aisme_labs_logo.svg";
import hamburger from "../../images/hamburger.svg";

const Header = (props) => {
  const [scroll, setScroll] = useState(false);
  const [activeItemIndex, setActiveItemIndex] = useState(props.active);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  }, []);

  return (
    <header
      className={`header d-flex align-items-center position-fixed ${
        scroll || props.headerSolid ? "header__scroll" : null
      }`}
    >
      <nav className="navbar navbar-expand-lg navbar-light w-100">
        <div className="container">
          <div className="d-flex justify-content-between header__wrapper">
            <Link to="/" className="d-flex align-items-center header__logo">
              <img src={logo} alt="logo" />
              <img src={logoBlk} alt="logo" />
            </Link>
            <button
              className="navbar-toggler header__hamburger shadow-none bg-white"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <img src={hamburger} alt="hamburger" />
              {/* <span className="navbar-toggler-icon"></span> */}
            </button>
          </div>
          <div
            className="collapse navbar-collapse justify-content-end "
            id="navbarNav"
          >
            <ul className="navbar-nav header__navbar">
              <li className="nav-item header__item">
                <Link
                  className={`nav-link header__link me-4 ${
                    activeItemIndex === 0 ? "active" : null
                  }`}
                  aria-current="page"
                  to="/"
                  onClick={() => setActiveItemIndex(0)}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item header__item">
                <Link
                  // to="/sme"
                  to="/"
                  className={`nav-link header__link me-4 ${
                    activeItemIndex === 1 ? "active" : null
                  }`}
                  onClick={() => setActiveItemIndex(1)}
                >
                  For SMEs
                </Link>
              </li>
              <li className="nav-item header__item">
                <Link
                  // to="/client"
                  to="/"
                  className={`nav-link header__link me-4 ${
                    activeItemIndex === 2 ? "active" : null
                  }`}
                  onClick={() => setActiveItemIndex(2)}
                >
                  For Clients
                </Link>
              </li>
              <li className="nav-item header__item">
                <Link
                  // to="/resources"
                  to="/"
                  className={`nav-link header__link me-4 ${
                    activeItemIndex === 3 ? "active" : null
                  }`}
                  onClick={() => setActiveItemIndex(3)}
                >
                  Resources
                </Link>
              </li>
              <li className="nav-item header__item">
                <Link
                  // to="/login"
                  to="/"
                  className={`nav-link header__link me-4 ${
                    activeItemIndex === 4 ? "active" : null
                  }`}
                  onClick={() => setActiveItemIndex(4)}
                >
                  Login
                </Link>
              </li>
            </ul>
            <button type="button" className="btn btn-primary mb-4 mt-sm-4 ">
              Get Started
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
