import React from "react";
import { Link } from "gatsby";

import arrowDown from "../../images/arrow-down.png";

const ContentTitle = () => {
  return (
    <div className="content-title">
      <div className="container">
        <div className="content-title__wrapper d-flex align-items-start justify-content-between">
          <div className="content-title__title">
            <h4 className="content-title__overtitle">How it works</h4>
            <h2 className="content-title__headText">
              Sample text
              <br /> goes here
            </h2>
          </div>
          <Link to="/">
            <img src={arrowDown} alt="" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ContentTitle;
